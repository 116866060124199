import {
  CreateUpdateBrandsParamsTypes,
  DeleteShopParamsTypes,
  GetBrandsDataTypes,
} from './types';

import axiosInstance from 'common/services/common/instance';

const getBrandsService = async (params?: BaseFilterParams):
  Promise<APIPaginationResponse<GetBrandsDataTypes[]>> => {
  const res = await axiosInstance.get('brands', { params });
  return res.data;
};

export const deleteBrandsService = async (
  params: DeleteShopParamsTypes
) => axiosInstance.delete('brands', { data: params });

export const createBrandsService = async (params: CreateUpdateBrandsParamsTypes):
  Promise<{ id: number }> => {
  const response = await axiosInstance.post('brands', params);
  return response.data.data;
};
export const getBrandsByIdService = async (
  params: { id: string }
): Promise<GetBrandsDataTypes> => {
  const res = await axiosInstance.get(`brands/${params.id}`);
  return res.data.data;
};

export const updateBrandsByIdService = async (id: number, params: CreateUpdateBrandsParamsTypes) => axiosInstance.put(`brands/${id}`, params);

export const changeStatusBrandService = async (ids: number, status: number)
  : Promise<void> => {
  await axiosInstance.put(`brands/${ids}/update-status`, { status });
};

export default getBrandsService;
