import {
  CreateUpdateCategoriesParamsTypes,
  DeleteShopParamsTypes,
  GetCategoriesDataTypes,
} from './types';

import axiosInstance from 'common/services/common/instance';

const getCategoriesService = async (params?: BaseFilterParams):
  Promise<APIPaginationResponse<GetCategoriesDataTypes[]>> => {
  const res = await axiosInstance.get('categories', { params });
  return res.data;
};

export const deleteCategoriesService = async (
  params: DeleteShopParamsTypes
) => axiosInstance.delete('categories', { data: params });

export const createCategoriesService = async (params: CreateUpdateCategoriesParamsTypes):
  Promise<{ id: number }> => {
  const response = await axiosInstance.post('categories', params);
  return response.data.data;
};
export const getCategoriesByIdService = async (
  params: { id: string }
): Promise<GetCategoriesDataTypes> => {
  const res = await axiosInstance.get(`categories/${params.id}`);
  return res.data.data;
};

export const updateCategoriesByIdService = async (id: number, params: CreateUpdateCategoriesParamsTypes) => axiosInstance.put(`categories/${id}`, params);

export const changeStatusCategoryService = async (ids: number, status: number)
  : Promise<void> => {
  await axiosInstance.put(`categories/${ids}/update-status`, { status });
};

export default getCategoriesService;
