import {
  IDistrict,
  IProvince,
  IWard,
  LocationParams,
} from './types';

import axiosInstance from 'common/services/common/instance';

export const getProvinceVNService = async (params: LocationParams): Promise<IProvince[]> => {
  const res = await axiosInstance.get('provinces', { params });
  return res.data.data;
};
export const getDistrictsService = async (
  params: LocationParams
): Promise<IDistrict[]> => {
  const res = await axiosInstance.get('districts', { params });
  return res.data.data;
};

export const getWardsService = async (params: LocationParams): Promise<IWard[]> => {
  const res = await axiosInstance.get('wards', { params });
  return res.data.data;
};
