import {
  CreateUpdateShopParamsTypes,
  DeleteShopParamsTypes,
  GetAllShopDataTypes,
  GetShopsByIdDataTypes,
} from './types';

import axiosInstance from 'common/services/common/instance';

const getShopsService = async (params?: BaseFilterParams):
  Promise<APIPaginationResponse<GetAllShopDataTypes[]>> => {
  const res = await axiosInstance.get('shops', { params });
  return res.data;
};

export const deleteShopService = async (
  params: DeleteShopParamsTypes
) => axiosInstance.delete('shops', { data: params });

export const createShopService = async (params: CreateUpdateShopParamsTypes):
  Promise<{ id: number }> => {
  const response = await axiosInstance.post('shops', params);
  return response.data.data;
};
export const getShopByIdService = async (
  params: { id: string }
): Promise<GetShopsByIdDataTypes> => {
  const res = await axiosInstance.get(`shops/${params.id}`);
  return res.data.data;
};
export const changeStatusShopService = async (ids: number, status: number)
  : Promise<void> => {
  await axiosInstance.put(`shops/${ids}/update-status`, { status });
};
export const updateShopByIdService = async (id: number, params: CreateUpdateShopParamsTypes) => axiosInstance.put(`shops/${id}`, params);

export default getShopsService;
