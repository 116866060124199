import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { getDistrictsService, getProvinceVNService, getWardsService } from 'common/services/extends/location';

interface Props {
  codeProvince?: string;
  codeDistrict?: string;
}

interface Response {
  wards: {
    options: OptionType[];
    loading?: boolean;
  };
  districts: {
    options: OptionType[];
    loading?: boolean;
  };
  provinces: {
    options: OptionType[];
    loading?: boolean;
  };
}

const useAddress = ({ codeDistrict, codeProvince }: Props): Response => {
  const [provinces, setProvinces] = useState<OptionType[]>([]);
  const [districts, setDistricts] = useState<OptionType[]>([]);
  const [wards, setWards] = useState<OptionType[]>([]);

  const { isLoading: loadingProvince } = useQuery(['get', 'province'], () => getProvinceVNService({ page: 1, limit: 200 }), {
    onSuccess: (res) => {
      setProvinces(res?.map((item) => ({
        label: item?.provinceData?.name,
        id: Number(item?.provinceData?.code),
        value: item?.provinceData?.id,
      })));
    }
  });

  const {
    mutate: mutateDistrict,
    isLoading: loadingDistrict
  } = useMutation(getDistrictsService, {
    onSuccess: (res) => {
      setDistricts(res?.map((item) => ({
        id: Number(item?.districtData?.code),
        label: item?.districtData?.name,
        value: item?.districtData?.id,
      })));
    }
  });

  const { mutate: mutateWard, isLoading: loadingWard } = useMutation(getWardsService, {
    onSuccess: (res) => {
      setWards(res?.map((item) => ({
        label: item?.wardData?.name,
        id: Number(item?.wardData?.code),
        value: item?.wardData?.id,
      })));
    }
  });

  useEffect(() => {
    if (codeProvince) {
      setDistricts([]);
      setWards([]);
      mutateDistrict({ provinceCode: codeProvince, page: 1, limit: 200 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeProvince]);

  useEffect(() => {
    if (codeDistrict) {
      setWards([]);
      mutateWard({ districtCode: codeDistrict, page: 1, limit: 200 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeDistrict]);

  return {
    wards: {
      options: wards,
      loading: loadingWard,
    },
    districts: {
      options: districts,
      loading: loadingDistrict,
    },
    provinces: {
      options: provinces,
      loading: loadingProvince,
    },
  };
};

export default useAddress;
