import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button, message, Modal, Space, Typography
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import StatusLabel from 'common/components/StatusLabel';
import getShopsService, { deleteShopService } from 'common/services/extends/shops';
import { ROUTE_PATHS } from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';

type ShopDataTypes = {
  id: number;
  name: string,
  url: string,
  slug: string,
  updatedAt: string;
  status: number;
  discountValue?: number;
  discount?: number;
  serviceFee?: number;
  brands: {
    id: number,
    name: string
  }[]
};

const ShopManagement: React.FC<ActiveRoles> = ({
  roleCreate, roleDelete, roleView, roleUpdate
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  /* Selectors */
  const {
    defaultPageSize
  } = useAppSelector((state) => state.system);

  /* States */
  const [currentPage, setCurrentPage] = useState(1);
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');

  /* Queries */
  const { data: shops, isLoading } = useQuery(
    ['getAllShop', keyword, currentPage, currentView],
    () => getShopsService({
      keyword, page: currentPage, limit: currentView
    }),
  );
  const { mutate: deleteShopMutate, isLoading: deleteStaticBlockLoading } = useMutation(
    ['shopsDelete'],
    async (ids: number[]) => deleteShopService({ ids }),
    {
      onSuccess: () => {
        message.success(t('message.deleteSuccess'));
        queryClient.invalidateQueries(['getAllShop']);
      },
      onError: () => {
        message.error(t('message.deleteError'));
      }
    }
  );

  /* Variables */
  const columns: ColumnsType<ShopDataTypes> = [
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      sorter: {
        compare: (a: ShopDataTypes, b: ShopDataTypes) => a.id - b.id,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: ShopDataTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    // --- Tên
    {
      title: t('system.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (
          a: ShopDataTypes,
          b: ShopDataTypes
        ) => a.name.localeCompare(b.name)
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: ShopDataTypes) => (
        <Typography.Text
          onClick={() => roleView && navigate(`${ROUTE_PATHS.SHOPS_DETAIL}?id=${data.id}`)}
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.name}
        </Typography.Text>
      ),
    },
    // --- slug
    {
      title: t('system.slug'),
      dataIndex: 'slug',
      key: 'slug',
      sorter: {
        compare: (
          a: ShopDataTypes,
          b: ShopDataTypes
        ) => a.slug.localeCompare(b.slug)
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: ShopDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.slug}
        </Typography.Text>
      ),
    },
    {
      title: t('shop.discountValue'),
      dataIndex: 'discountValue',
      key: 'discountValue',
      sorter: {
        compare: (
          a: ShopDataTypes,
          b: ShopDataTypes
        ) => Number(a.discountValue) - Number(b.discountValue)
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: ShopDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.discountValue}
        </Typography.Text>
      ),
    },
    {
      title: t('shop.discount'),
      dataIndex: 'discount',
      key: 'discount',
      sorter: {
        compare: (
          a: ShopDataTypes,
          b: ShopDataTypes
        ) => Number(a.discount) - Number(b.discount)
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: ShopDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.discount}
        </Typography.Text>
      ),
    },
    {
      title: t('shop.serviceFeePercent'),
      dataIndex: 'serviceFeePercent',
      key: 'serviceFeePercent',
      sorter: {
        compare: (
          a: ShopDataTypes,
          b: ShopDataTypes
        ) => Number(a.serviceFee) - Number(b.serviceFee)
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: ShopDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.serviceFee}
        </Typography.Text>
      ),
    },
    // --- url
    {
      title: t('formManagement.urlTypeLabel'),
      dataIndex: 'url',
      key: 'url',
      sorter: {
        compare: (
          a: ShopDataTypes,
          b: ShopDataTypes
        ) => a.url.localeCompare(b.url)
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: ShopDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.url}
        </Typography.Text>
      ),
    },
    {
      title: t('formManagement.brands'),
      dataIndex: 'brands',
      key: 'brands',
      render: (_: string, data: ShopDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.brands?.map((val) => val.name).join(', ')}
        </Typography.Text>
      ),
    },
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: {
        compare: (a: ShopDataTypes, b: ShopDataTypes) => {
          const aDate = new Date(a.updatedAt);
          const bDate = new Date(b.updatedAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: ShopDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.updatedAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('system.status'),
      dataIndex: 'status',
      key: 'status',
      width: 160,
      render: (_name: string, data: ShopDataTypes) => (
        <StatusLabel status={data.status} />
      ),
    },
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, data: ShopDataTypes) => (
        <Space>
          <Button
            disabled={!roleUpdate}
            icon={<EditOutlined />}
            onClick={() => navigate(`${ROUTE_PATHS.SHOPS_DETAIL}?id=${data.id}`)}
          />
          <Button
            disabled={!roleDelete}
            icon={<DeleteOutlined />}
            onClick={() => {
              Modal.confirm({
                className: 't-pagetable_deleteRecordModal',
                autoFocusButton: 'cancel',
                title: t('message.confirmDeleteRecord'),
                okText: t('system.ok'),
                cancelText: t('system.cancel'),
                cancelButtonProps: {
                  type: 'primary',
                },
                okButtonProps: {
                  type: 'default',
                },
                onOk: () => {
                  deleteShopMutate([data.id]);
                },
              });
            }}
          />
        </Space>
      ),
    },
  ];

  const shopData = useMemo(() => (shops?.data?.map((val) => ({
    id: val.shopData.id,
    name: val.shopData.name,
    url: val.shopData.url,
    discountValue: val.shopData.discountValue,
    discount: val.shopData.discount,
    slug: val.shopData.slug,
    status: val.shopData.status,
    serviceFee: val.shopData.serviceFee,
    updatedAt: val.shopData.updatedAt,
    brands: val.brands
  })) || []), [shops]);

  /* Functions */
  const onDelete = async (data: ShopDataTypes[], code?: string) => {
    switch (code) {
      case 'allRow':
      case 'all': {
        deleteShopMutate(data.map((item) => item.id));
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('system.shops')}
        rightHeader={(
          <Button type="primary" disabled={!roleCreate} onClick={() => navigate(`${ROUTE_PATHS.SHOPS_DETAIL}`)}>
            <PlusOutlined />
            {t('system.create')}
          </Button>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          handleDelete={onDelete}
          handleSearch={setKeyword}
          isLoading={isLoading || deleteStaticBlockLoading}
          roles={{
            roleCreate,
            roleDelete,
            roleUpdate
          }}
          tableProps={{
            initShowColumns: ['id', 'name', 'slug', 'discountValue', 'discount', 'serviceFeePercent', 'url', 'brands', 'updatedAt', 'status', 'action'],
            columns,
            pageData: shopData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: shops?.meta.total,
            noDeleteLanguage: true,
            noBaseCol: true
          }}
        />
      </div>
    </>
  );
};

export default ShopManagement;
