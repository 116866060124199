import { SaveOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Card,
  Col, message, Row, Space, Spin, Table, Typography
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, {
  useEffect,
  useState
} from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import Link from 'common/components/Link';
import ManagementInfo from 'common/components/ManagementInfo';
import ModalConfirm from 'common/components/ModalConfirm';
import Pulldown from 'common/components/Pulldown';
import useAddress from 'common/hooks/useAddress';
import { getOrderByIdService, updateOrderByIdService } from 'common/services/extends/orders';
import { CreateUpdateOrderParamsTypes } from 'common/services/extends/orders/types';
import { numberWithPrefix } from 'common/utils/functions';
import { useValidations } from 'common/utils/schemas';

interface ItemTypes {
  id: number,
  name: string,
  originalPrice: number,
  price: number,
  quantity: number,
  total: number,
  tax: number,
  grandTotal: number,
  note: string,
  url: string,
}

type MainDataTypes = {
  status: OptionType;
  paidStatus: OptionType;
  saleNote: string;
  landingCode: string;
  shippingCost: string;
  shippingVNCost: string;
  shippingAddress: {
    name: string,
    phone: string,
    email: string,
    address: string,
    province: OptionType,
    district: OptionType,
    ward: OptionType
  }
  items: ItemTypes[];
  amountPaid: string,
  remainAmounting: string,
  discount: number,
  serviceFee: number;
  serviceFeePrice: number;
};

export const statusData = [
  {
    label: 'Chờ xác nhận',
    value: 1,
  },
  {
    label: 'Đã đặt hàng',
    value: 2,
  },
  {
    label: 'Đã nhập kho',
    value: 3,
  },
  {
    label: 'Chờ giao hàng',
    value: 4,
  },
  {
    label: 'Đang giao hàng',
    value: 5,
  },
  {
    label: 'Đã giao hàng',
    value: 6,
  },
  {
    label: 'Hủy',
    value: 8,
  },
];

export const paidStatusData = [
  {
    label: 'Chưa thanh toán',
    value: 1,
  },
  {
    label: 'Đã thanh toán',
    value: 2,
  },
  {
    label: 'Thanh toán một phần',
    value: 3,
  },
];

const OrderDetail: React.FC<ActiveRoles> = ({ roleCreate, roleUpdate }) => {
  const { orderDetail } = useValidations();

  const mainMethod = useForm<MainDataTypes>({
    resolver: yupResolver(orderDetail),
    mode: 'onChange',
    defaultValues: {
    }
  });

  /* Hooks */
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const idParam = Number(searchParams.get('id'));

  /* States */
  const [confirm, setConfirm] = useState<string | undefined>(undefined);

  const { data: detailOrder, isLoading: loadingDetail } = useQuery(
    ['getDetailOrder', idParam],
    () => {
      if (idParam) {
        return getOrderByIdService({ id: String(idParam) });
      }
      return undefined;
    }
  );

  const { mutate: mutateUpdateOrderByIdService, isLoading: updateLoading } = useMutation(
    async (data: {
      id: number;
      params: CreateUpdateOrderParamsTypes
    }) => updateOrderByIdService(data.id, data.params),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
      },
      onError: () => {
        message.error(t('message.updateError'));
      }
    }
  );

  const handleSubmit = async () => {
    const result = await mainMethod.trigger();
    if (!result) {
      return;
    }

    const mainData = mainMethod.getValues();

    const dataSubmit: CreateUpdateOrderParamsTypes = {
      shippingCost: Number(mainData.shippingCost),
      shippingVNCost: Number(mainData.shippingVNCost),
      ladingCode: mainData.landingCode,
      status: Number(mainData?.status?.value),
      paidStatus: Number(mainData?.paidStatus?.value),
      saleNote: mainData?.saleNote,
      paid: Number(mainData.amountPaid.replaceAll(',', '').replaceAll('.', '')),
      items: mainData?.items?.map((item) => ({
        id: item.id,
        quantity: Number(item?.quantity),
        originalPrice: Number(item.originalPrice)
      })),
      shippingAddress: {
        name: mainData.shippingAddress.name,
        phone: mainData.shippingAddress.phone,
        email: mainData.shippingAddress.email,
        address: mainData.shippingAddress.address,
        provinceId: Number(mainData.shippingAddress?.province?.value),
        districtId: Number(mainData.shippingAddress?.district?.value),
        wardId: Number(mainData.shippingAddress?.ward?.value)
      },
    };

    try {
      if (idParam) {
        mutateUpdateOrderByIdService({
          id: idParam,
          params: dataSubmit
        });
      }
    } catch (error: any) {
      message.error(idParam ? t('system.updateError') : t('shops.createError'));
    }
  };

  /* Effects */
  useEffect(() => {
    if (detailOrder) {
      mainMethod.reset({
        status: statusData.find((item) => item.value === detailOrder.orderData.status),
        paidStatus: paidStatusData.find((item) => item.value === detailOrder.orderData.paidStatus),
        saleNote: detailOrder.orderData.saleNote,
        landingCode: detailOrder?.orderData?.ladingCode?.toString(),
        shippingCost: detailOrder?.orderData?.shippingCost?.toString(),
        shippingVNCost: detailOrder?.orderData?.shippingVNCost?.toString(),
        shippingAddress: {
          ...detailOrder.shippingAddress,
          address: detailOrder.shippingAddress.address || '',
          province: detailOrder.shippingAddress.province ? {
            label: detailOrder.shippingAddress.province.name,
            value: detailOrder.shippingAddress.province.id,
          } : undefined,
          district: detailOrder.shippingAddress.district ? {
            label: detailOrder.shippingAddress.district.name,
            value: detailOrder.shippingAddress.district.id,
          } : undefined,
          ward: detailOrder.shippingAddress.ward ? {
            label: detailOrder.shippingAddress.ward.name,
            value: detailOrder.shippingAddress.ward.id,
          } : undefined
        },
        amountPaid: detailOrder.orderData.paid.toLocaleString(),
        remainAmounting: detailOrder.orderData.remainingPaid.toLocaleString(),
        discount: detailOrder.orderData.discount,
        serviceFee: detailOrder.orderData.serviceFee,
        serviceFeePrice: detailOrder.orderData.serviceFeePrice,
        items: detailOrder.items.map((item): ItemTypes => {
          const taxPrice = (item.total * detailOrder.orderData.tax) / 100;
          return {
            ...item,
            tax: detailOrder.orderData.tax,
            grandTotal: item.total + taxPrice,
          };
        }),
      });
    } else {
      mainMethod.reset();
    }
  }, [detailOrder, mainMethod]);

  const calcTotalPriceWithTax = () => {
    const totalPriceAllProducts = mainMethod.getValues('items')?.reduce((prev, current) => prev + current.total, 0);
    const taxFinal = (totalPriceAllProducts * (detailOrder?.orderData.tax || 0)) / 100;
    const discountPrice = detailOrder?.orderData && detailOrder?.orderData.discount > 0 ? (
      totalPriceAllProducts * detailOrder.orderData.discount
    ) / 100 : 0;
    const totalPriceWithTax = totalPriceAllProducts + taxFinal - discountPrice;
    const serviceFeePrice = (mainMethod.getValues('serviceFee') * totalPriceWithTax) / 100;
    return {
      totalPriceAllProducts,
      taxFinal,
      totalPriceWithTax,
      discountPrice,
      serviceFeePrice
    };
  };

  const columns: ColumnsType<ItemTypes> = [
    {
      title: t('order.nameProduct'),
      dataIndex: 'name',
      key: 'name',
      width: '22%',
    },
    {
      title: t('order.originalPrice'),
      dataIndex: 'originalPrice',
      key: 'originalPrice',
      render(value, record, index) {
        return (
          <Controller
            name={`items.${index}.originalPrice`}
            rules={{
              min: 'oke'
            }}
            render={({ field, fieldState: { error } }) => (
              <Input
                type="number"
                placeholder="originalPrice"
                {...field}
                onChange={(val) => {
                  field.onChange(val);
                  const price = Number(val.target.value)
                    * (detailOrder?.orderData?.exchangeRate || 0);
                  mainMethod.setValue(`items.${index}.price`, price);
                  mainMethod.setValue(`items.${index}.total`, price * Number(mainMethod.getValues(`items.${index}.quantity`)));

                  const paid = mainMethod.getValues('amountPaid').replaceAll(',', '').replaceAll('.', '');

                  const priceRemain = calcTotalPriceWithTax().totalPriceWithTax
                    - Number(paid || 0);
                  mainMethod.setValue('remainAmounting', priceRemain.toLocaleString());
                }}
                error={error?.message}
              />
            )}
          />
        );
      },
      width: '12%',
    },
    {
      title: t('order.price'),
      dataIndex: 'price',
      key: 'price',
      render(value, record) {
        return (
          <Typography.Text>
            {numberWithPrefix(record.price)}
          </Typography.Text>
        );
      },
      width: '20%',
    },
    {
      title: t('order.quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
      render(value, record, index) {
        return (
          <Controller
            name={`items.${index}.quantity`}
            rules={{
              min: 'oke'
            }}
            render={({ field, fieldState: { error } }) => (
              <Input
                type="number"
                placeholder="quantity"
                {...field}
                onChange={(val) => {
                  field.onChange(val);
                  const total = mainMethod.getValues(`items.${index}.price`) * Number(val.target.value);
                  mainMethod.setValue(`items.${index}.total`, total);

                  const paid = mainMethod.getValues('amountPaid').replaceAll(',', '').replaceAll('.', '');

                  const priceRemain = calcTotalPriceWithTax().totalPriceWithTax
                    - Number(paid || 0);
                  mainMethod.setValue('remainAmounting', priceRemain.toLocaleString());
                }}
                error={error?.message}
              />
            )}
          />
        );
      },
      width: '10%',
    },
    {
      title: t('order.total'),
      dataIndex: 'total',
      key: 'total',
      render(value, record) {
        return (
          <Typography.Text>
            {numberWithPrefix(record.total)}
          </Typography.Text>
        );
      },
    },
    {
      title: t('order.note'),
      dataIndex: 'note',
      key: 'note',
      width: '15%',
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
      render(_: string, record) {
        return (
          <Link useExternal target="_blank" href={record.url}>
            {record.url}
          </Link>
        );
      },
    },
  ];

  const codeProvince = mainMethod.watch('shippingAddress.province');
  const codeDistrict = mainMethod.watch('shippingAddress.district');

  const { provinces, districts, wards } = useAddress({
    codeProvince: codeProvince?.id?.toString(),
    codeDistrict: codeDistrict?.id?.toString()
  });

  const detectPaymentMethod = (paymentMethod?: number) => {
    switch (paymentMethod) {
      case 1:
        return 'Chuyển khoản';
      case 2:
        return 'MoMo';
      default:
        return '';
    }
  };

  /* Render */
  return (
    <div className="p-editStaticBlock">
      <HeaderPage
        fixed
        title={idParam ? t('system.edit') : t('system.create')}
        rightHeader={(
          <Button
            type="primary"
            disabled={(idParam && !roleUpdate) || (!idParam && !roleCreate)}
            onClick={() => handleSubmit()}
            loading={updateLoading}
          >
            <SaveOutlined />
            {t('system.save')}
          </Button>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <FormProvider {...mainMethod}>
          <Row gutter={16}>
            <Col xxl={18} xl={16} lg={16}>
              {loadingDetail ? <Spin className="center-absolute" size="large" spinning />
                : (
                  <div className="site-card-border-less-wrapper">
                    <Space direction="vertical" size={12} style={{ width: '100%' }}>
                      <Card>
                        <Row gutter={16}>
                          <Col span={12}>
                            <div className="p-editPageTemplate_input">
                              <Typography.Text strong>
                                {t('system.status')}
                                {' '}
                              </Typography.Text>
                              <Typography.Text strong type="danger">
                                *
                              </Typography.Text>
                              <Controller
                                name="status"
                                control={mainMethod.control}
                                render={({
                                  field: { value, onChange },
                                  fieldState: { error },
                                }) => (
                                  <Pulldown
                                    value={value}
                                    size="sm"
                                    placeholder="---"
                                    handleSelect={onChange}
                                    options={statusData}
                                    error={error?.message}
                                  />
                                )}
                              />
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="p-editPageTemplate_input">
                              <Typography.Text strong>
                                {t('order.paidStatus')}
                                {' '}
                              </Typography.Text>
                              <Typography.Text strong type="danger">
                                *
                              </Typography.Text>
                              <Controller
                                name="paidStatus"
                                control={mainMethod.control}
                                render={({
                                  field: { value, onChange },
                                  fieldState: { error },
                                }) => (
                                  <Pulldown
                                    value={value}
                                    size="sm"
                                    placeholder="---"
                                    handleSelect={onChange}
                                    options={paidStatusData}
                                    error={error?.message}
                                  />
                                )}
                              />
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="p-editPageTemplate_input u-mt-12">
                              <Typography.Text strong>
                                {t('order.landingCode')}
                              </Typography.Text>
                              <Controller
                                name="landingCode"
                                control={mainMethod.control}
                                render={({
                                  field: { value, onChange },
                                  fieldState: { error },
                                }) => (
                                  <Input
                                    className="u-mt-8"
                                    value={value}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                    error={error?.message}
                                    size="large"
                                  />
                                )}
                              />
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="p-editPageTemplate_input u-mt-12">
                              <Typography.Text strong>
                                {t('order.transportFee')}
                              </Typography.Text>
                              <Controller
                                name="shippingCost"
                                control={mainMethod.control}
                                render={({
                                  field: { value, onChange },
                                  fieldState: { error },
                                }) => (
                                  <Input
                                    className="u-mt-8"
                                    value={value}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                    error={error?.message}
                                    size="large"
                                  />
                                )}
                              />
                            </div>
                          </Col>
                          <Col span={24}>
                            <div className="p-editPageTemplate_input u-mt-12">
                              <Typography.Text strong>
                                {t('order.transportFeeVN')}
                              </Typography.Text>
                              <Controller
                                name="shippingVNCost"
                                control={mainMethod.control}
                                render={({
                                  field: { value, onChange },
                                  fieldState: { error },
                                }) => (
                                  <Input
                                    className="u-mt-8"
                                    value={value}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                    error={error?.message}
                                    size="large"
                                  />
                                )}
                              />
                            </div>
                          </Col>
                          <Col span={24}>
                            <div className="p-editPageTemplate_input u-mt-12">
                              <Typography.Text strong>
                                {t('order.saleNote')}
                              </Typography.Text>
                              <Controller
                                name="saleNote"
                                control={mainMethod.control}
                                render={({
                                  field: { value, onChange },
                                  fieldState: { error },
                                }) => (
                                  <Input
                                    className="u-mt-8"
                                    value={value}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                    error={error?.message}
                                    size="large"
                                  />
                                )}
                              />
                            </div>
                          </Col>
                          <Col span={24}>
                            <div className="p-editPageTemplate_input u-mt-24">
                              <Typography.Text strong>
                                {t('order.productList')}
                              </Typography.Text>
                              <Table
                                pagination={{ hideOnSinglePage: true }}
                                columns={columns}
                                dataSource={mainMethod.watch('items')}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col span={24}>
                            <div className="p-editPageTemplate_input u-mt-12">
                              <Typography.Text strong style={{ fontSize: '16px' }}>
                                {t('order.total')}
                                :
                                {' '}
                              </Typography.Text>
                              <Typography.Text style={{ fontSize: '16px' }}>
                                {`${calcTotalPriceWithTax().totalPriceAllProducts?.toLocaleString()} VND`}
                              </Typography.Text>
                            </div>
                          </Col>
                          <Col span={24}>
                            <div className="p-editPageTemplate_input u-mt-12">
                              <Typography.Text strong style={{ fontSize: '16px' }}>
                                {t('order.tax')}
                                :
                                {' '}
                              </Typography.Text>
                              <Typography.Text style={{ fontSize: '16px' }}>
                                {`${detailOrder?.orderData.tax}%`}
                              </Typography.Text>
                            </div>
                          </Col>
                          <Col span={24}>
                            <div className="p-editPageTemplate_input u-mt-12">
                              <Typography.Text strong style={{ fontSize: '16px' }}>
                                {t('order.taxPrice')}
                                :
                                {' '}
                              </Typography.Text>
                              <Typography.Text style={{ fontSize: '16px' }}>
                                {`${calcTotalPriceWithTax().taxFinal?.toLocaleString()} VND`}
                              </Typography.Text>
                            </div>
                          </Col>
                          <Col span={24}>
                            <div className="p-editPageTemplate_input u-mt-12">
                              <Typography.Text strong style={{ fontSize: '16px' }}>
                                {t('order.discount')}
                                {` (${detailOrder?.orderData.discount}%): `}
                              </Typography.Text>
                              <Typography.Text style={{ fontSize: '16px' }}>
                                {`${calcTotalPriceWithTax().discountPrice > 0 ? `-${calcTotalPriceWithTax().discountPrice?.toLocaleString()} VND` : '0 VND'}`}
                              </Typography.Text>
                            </div>
                          </Col>
                          <Col span={24}>
                            <div className="p-editPageTemplate_input u-mt-12">
                              <Typography.Text strong style={{ fontSize: '16px' }}>
                                {t('order.serviceFeePrice')}
                                {` (${detailOrder?.orderData.serviceFee}%): `}
                              </Typography.Text>
                              <Typography.Text style={{ fontSize: '16px' }}>
                                {`${(detailOrder?.orderData.serviceFee || 0) > 0 ? `${calcTotalPriceWithTax().serviceFeePrice?.toLocaleString()} VND` : '0 VND'}`}
                              </Typography.Text>
                            </div>
                          </Col>
                          <Col span={24}>
                            <div className="p-editPageTemplate_input u-mt-12">
                              <Typography.Text strong style={{ fontSize: '16px' }}>
                                {t('order.grandTotal')}
                                :
                                {' '}
                              </Typography.Text>
                              <Typography.Text style={{ fontSize: '16px' }}>
                                {`${calcTotalPriceWithTax().totalPriceWithTax?.toLocaleString()} VND`}
                              </Typography.Text>
                            </div>
                          </Col>
                          <Col span={24}>
                            <div className="p-editPageTemplate_amountPaid u-mt-12">
                              <Typography.Text strong style={{ fontSize: '16px', marginRight: '8px' }}>
                                {t('order.amountPaid')}
                                :
                                {' '}
                              </Typography.Text>
                              <div className="p-editPageTemplate_amountPaid-input">
                                <Controller
                                  name="amountPaid"
                                  control={mainMethod.control}
                                  render={({
                                    field: { value, onChange },
                                    fieldState: { error },
                                  }) => (
                                    <Input
                                      placeholder={t('order.amountPaid')}
                                      value={value}
                                      onChange={(val) => {
                                        const removeDotValue = val.target.value.replaceAll('.', '').replaceAll(',', '');
                                        const valueAmountPaid = val.target.value
                                          ? Number(removeDotValue) : 0;

                                        if (Number(removeDotValue) <= calcTotalPriceWithTax()
                                          .totalPriceWithTax) {
                                          const dataChange = {
                                            ...val,
                                            target: {
                                              value: removeDotValue
                                                ? Number(removeDotValue).toLocaleString() : ''
                                            }
                                          };

                                          onChange(dataChange);

                                          const priceRemain = calcTotalPriceWithTax()
                                            .totalPriceWithTax
                                            - valueAmountPaid;
                                          mainMethod.setValue('remainAmounting', priceRemain.toLocaleString());
                                        }
                                      }}
                                      error={error?.message}
                                    />
                                  )}
                                />
                              </div>
                              <Typography.Text style={{ fontSize: '16px', marginLeft: '10px' }}>
                                VND
                              </Typography.Text>
                            </div>
                          </Col>
                          <Col span={24}>
                            <div className="p-editPageTemplate_input u-mt-12">
                              <Typography.Text strong style={{ fontSize: '16px' }}>
                                {t('order.remainAmounting')}
                                :
                                {' '}
                              </Typography.Text>
                              <Typography.Text
                                style={{
                                  fontSize: '16px',
                                  color: Number(mainMethod.watch('remainAmounting')?.replaceAll(',', '').replaceAll('.', '')) > 0 ? 'red' : 'unset'
                                }}
                              >
                                {`${mainMethod.watch('remainAmounting')} VND`}
                              </Typography.Text>
                            </div>
                          </Col>
                        </Row>
                      </Card>
                      <Card title="Shipping Address">
                        <Row gutter={16}>
                          <Col span={8}>
                            <div className="p-editPageTemplate_input u-mt-12">
                              <Typography.Text strong>
                                {t('system.name')}
                                {' '}
                              </Typography.Text>
                              <Typography.Text strong type="danger">
                                *
                              </Typography.Text>
                              <Controller
                                name="shippingAddress.name"
                                control={mainMethod.control}
                                render={({
                                  field: { value, onChange },
                                  fieldState: { error },
                                }) => (
                                  <Input
                                    className="u-mt-8"
                                    value={value}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                    error={error?.message}
                                    size="large"
                                  />
                                )}
                              />
                            </div>
                          </Col>
                          <Col span={8}>
                            <div className="p-editPageTemplate_input u-mt-12">
                              <Typography.Text strong>
                                {t('system.phone')}
                                {' '}
                              </Typography.Text>
                              <Typography.Text strong type="danger">
                                *
                              </Typography.Text>
                              <Controller
                                name="shippingAddress.phone"
                                control={mainMethod.control}
                                render={({
                                  field: { value, onChange },
                                  fieldState: { error },
                                }) => (
                                  <Input
                                    className="u-mt-8"
                                    placeholder="phone"
                                    value={value}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                    error={error?.message}
                                    size="large"
                                  />
                                )}
                              />
                            </div>
                          </Col>
                          <Col span={8}>
                            <div className="p-editPageTemplate_input u-mt-12">
                              <Typography.Text strong>
                                Email
                                {' '}
                              </Typography.Text>
                              <Typography.Text strong type="danger">
                                *
                              </Typography.Text>
                              <Controller
                                name="shippingAddress.email"
                                control={mainMethod.control}
                                render={({
                                  field: { value, onChange },
                                  fieldState: { error },
                                }) => (
                                  <Input
                                    className="u-mt-8"
                                    placeholder="phone"
                                    value={value}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                    error={error?.message}
                                    size="large"
                                  />
                                )}
                              />
                            </div>
                          </Col>
                          <Col span={8}>
                            <div className="p-editPageTemplate_input u-mt-12">
                              <Typography.Text strong>
                                {t('order.province')}
                                {' '}
                              </Typography.Text>
                              <Typography.Text strong type="danger">
                                *
                              </Typography.Text>
                              <Controller
                                name="shippingAddress.province"
                                control={mainMethod.control}
                                render={({
                                  field: { value, onChange },
                                  fieldState: { error },
                                }) => (
                                  <Pulldown
                                    value={value}
                                    size="sm"
                                    placeholder="---"
                                    handleSelect={(option) => {
                                      onChange(option);
                                      mainMethod.resetField('shippingAddress.district', { defaultValue: null });
                                      mainMethod.resetField('shippingAddress.ward', { defaultValue: null });
                                    }}
                                    options={provinces.options}
                                    error={error?.message}
                                  />
                                )}
                              />
                            </div>
                          </Col>
                          <Col span={8}>
                            <div className="p-editPageTemplate_input u-mt-12">
                              <Typography.Text strong>
                                {t('order.district')}
                                {' '}
                              </Typography.Text>
                              <Typography.Text strong type="danger">
                                *
                              </Typography.Text>
                              <Controller
                                name="shippingAddress.district"
                                control={mainMethod.control}
                                render={({
                                  field: { value, onChange },
                                  fieldState: { error },
                                }) => (
                                  <Pulldown
                                    value={value}
                                    size="sm"
                                    placeholder="---"
                                    handleSelect={(option) => {
                                      onChange(option);
                                      mainMethod.resetField('shippingAddress.ward', { defaultValue: null });
                                    }}
                                    error={error?.message}
                                    options={districts.options}
                                  />
                                )}
                              />
                            </div>
                          </Col>
                          <Col span={8}>
                            <div className="p-editPageTemplate_input u-mt-12">
                              <Typography.Text strong>
                                {t('order.ward')}
                                {' '}
                              </Typography.Text>
                              <Typography.Text strong type="danger">
                                *
                              </Typography.Text>
                              <Controller
                                name="shippingAddress.ward"
                                control={mainMethod.control}
                                render={({
                                  field: { value, onChange },
                                  fieldState: { error },
                                }) => (
                                  <Pulldown
                                    value={value}
                                    size="sm"
                                    placeholder="---"
                                    handleSelect={onChange}
                                    options={wards.options}
                                    error={error?.message}
                                  />
                                )}
                              />
                            </div>
                          </Col>
                          <Col span={24}>
                            <div className="p-editPageTemplate_input u-mt-12">
                              <Typography.Text strong>
                                {t('footer.address')}
                                {' '}
                              </Typography.Text>
                              <Typography.Text strong type="danger">
                                *
                              </Typography.Text>
                              <Controller
                                name="shippingAddress.address"
                                control={mainMethod.control}
                                render={({
                                  field: { value, onChange },
                                  fieldState: { error },
                                }) => (
                                  <Input
                                    className="u-mt-8"
                                    value={value}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                    error={error?.message}
                                    size="large"
                                  />
                                )}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Card>
                    </Space>
                  </div>
                )}
            </Col>
            <Col xxl={6} xl={8} lg={8}>
              <ManagementInfo
                createdDate={detailOrder ? moment(detailOrder.orderData.createdAt).format('HH:mm DD/MM/yyyy') : ''}
                createdBy=""
                lastUpdated={detailOrder ? moment(detailOrder?.orderData.updatedAt).format('HH:mm DD/MM/yyyy') : ''}
                lastUpdatedBy="Admin"
              />
              <Card>
                <div className="p-editPageTemplate_sideBar-item">
                  <Typography.Text strong>{t('order.shop')}</Typography.Text>
                  <Typography.Text type="secondary">{detailOrder?.shop.name}</Typography.Text>
                </div>
                <div className="p-editPageTemplate_sideBar-item">
                  <Typography.Text strong>{t('order.codeOrder')}</Typography.Text>
                  <Typography.Text type="secondary">{detailOrder?.orderData.code}</Typography.Text>
                </div>
                <div className="p-editPageTemplate_sideBar-item">
                  <Typography.Text strong>{t('order.paymentMethod')}</Typography.Text>
                  <Typography.Text type="secondary">
                    {
                      detectPaymentMethod(detailOrder?.orderData.paymentMethod)
                    }
                  </Typography.Text>
                </div>
                <div className="p-editPageTemplate_sideBar-item">
                  <Typography.Text strong>{t('systemManagement.exchangeRate')}</Typography.Text>
                  <Typography.Text type="secondary">{detailOrder?.orderData.exchangeRate?.toLocaleString().concat(' VND')}</Typography.Text>
                </div>
              </Card>
            </Col>
          </Row>
        </FormProvider>
      </div>
      <ModalConfirm
        isShow={!!confirm}
        handleCancel={() => { }}
        handleConfirm={() => { }}
        handleClose={() => setConfirm(undefined)}
      >
        {t('message.confirmSave')}
      </ModalConfirm>
    </div>
  );
};

export default OrderDetail;
