import { DeleteOutlined, EditOutlined, ExportOutlined } from '@ant-design/icons';
import {
  Button,
  Modal, Space, Typography,
  message
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import {
  FilterValueProps, mappingFilterToQuery, mappingParamsFilter, mappingQueryParamsFilter,
  FilterDataProps, mappingFilterFields
} from 'common/components/PageTable/filter';
import { deleteOrderService, getOrdersExportsService, getOrdersService } from 'common/services/extends/orders';
import { ROUTE_PATHS } from 'common/utils/constant';
import { formatDateTime, numberWithPrefix } from 'common/utils/functions';
import roles from 'configs/roles';
import { paidStatusData, statusData } from 'extends/OrderDetail';

type OrderTableDataTypes = {
  id: number;
  code: string,
  total: number,
  tax: number,
  grandTotal: number,
  updatedAt: string;
  status: number;
  paidStatus: number;
  nameShop?: string;
  amountPaid: number;
  remainAmounting: number;
  discount: number;
  serviceFee?: number;
  serviceFeePrice?: number;
};

const OrderManagement: React.FC<ActiveRoles> = ({
  roleDelete, roleView, roleUpdate, roleOther
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams.entries());

  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));

  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );

  /* Selectors */
  const {
    defaultPageSize,
    advancedFilter
  } = useAppSelector((state) => state.system);

  /* States */
  const [currentPage, setCurrentPage] = useState(1);
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');

  /* Queries */
  const { data: orders, isLoading } = useQuery(
    ['getAllOrder', keyword, currentPage, currentView, selectedFilterList],
    () => getOrdersService({
      keyword, page: currentPage, limit: currentView, ...returnParamsFilter
    }),
  );

  const { mutate: deleteOrdersMutate, isLoading: deleteStaticBlockLoading } = useMutation(
    ['ordersDelete'],
    async (ids: number[]) => deleteOrderService({ ids }),
    {
      onSuccess: () => {
        message.success(t('message.deleteSuccess'));
        queryClient.invalidateQueries(['getAllOrder']);
      },
      onError: () => {
        message.error(t('message.deleteError'));
      }
    }
  );

  const { mutate: exportOrdersMutate, isLoading: exportOrdersLoading } = useMutation(
    ['exportOrders'],
    async () => getOrdersExportsService({
      keyword, page: currentPage, limit: currentView, ...returnParamsFilter
    }),
    {
      onSuccess: (res: { link: string }) => {
        message.success(t('message.exportSuccess'));
        if (res.link) window.open(res.link, '_blank');
      },
      onError: () => {
        message.error(t('message.exportError'));
      }
    }
  );

  /* Variables */
  const columns: ColumnsType<OrderTableDataTypes> = [
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      sorter: {
        compare: (a: OrderTableDataTypes, b: OrderTableDataTypes) => a.id - b.id,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: OrderTableDataTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    // --- Tên cửa hàng
    {
      title: t('order.shop'),
      dataIndex: 'shop',
      key: 'shop',
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: OrderTableDataTypes) => (
        <Typography.Text>
          {data.nameShop}
        </Typography.Text>
      ),
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      sorter: {
        compare: (
          a: OrderTableDataTypes,
          b: OrderTableDataTypes
        ) => a.code.localeCompare(b.code)
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: OrderTableDataTypes) => (
        <Typography.Text
          onClick={() => roleView && navigate(`${ROUTE_PATHS.ORDER_DETAIL}?id=${data.id}`)}
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.code}
        </Typography.Text>
      ),
    },
    // --- Tổng giá trị đơn hàng
    {
      title: t('order.total'),
      dataIndex: 'total',
      key: 'total',
      sorter: {
        compare: (
          a: OrderTableDataTypes,
          b: OrderTableDataTypes
        ) => a.total - b.total
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: OrderTableDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {numberWithPrefix(data.total)}
        </Typography.Text>
      ),
    },
    {
      title: t('order.tax'),
      dataIndex: 'tax',
      key: 'tax',
      sorter: {
        compare: (
          a: OrderTableDataTypes,
          b: OrderTableDataTypes
        ) => a.tax - b.tax
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: OrderTableDataTypes) => (
        <Typography.Text>
          {data.tax}
        </Typography.Text>
      ),
    },
    {
      title: t('order.grandTotal'),
      dataIndex: 'grandTotal',
      key: 'grandTotal',
      sorter: {
        compare: (
          a: OrderTableDataTypes,
          b: OrderTableDataTypes
        ) => a.grandTotal - b.grandTotal
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: OrderTableDataTypes) => (
        <Typography.Text>
          {numberWithPrefix(data.grandTotal)}
        </Typography.Text>
      ),
    },
    {
      title: t('order.discount'),
      dataIndex: 'discount',
      key: 'discount',
      sorter: {
        compare: (
          a: OrderTableDataTypes,
          b: OrderTableDataTypes
        ) => a.discount - b.discount
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: OrderTableDataTypes) => (
        <Typography.Text>
          {numberWithPrefix(data.discount)}
        </Typography.Text>
      ),
    },
    {
      title: t('order.paidStatus'),
      dataIndex: 'paidStatus',
      key: 'paidStatus',
      sorter: {
        compare: (
          a: OrderTableDataTypes,
          b: OrderTableDataTypes
        ) => a.paidStatus - b.paidStatus
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: OrderTableDataTypes) => (
        <Typography.Text>
          {
            paidStatusData.find((item) => (item.value === data.paidStatus))?.label
          }
        </Typography.Text>
      ),
    },
    {
      title: t('order.amountPaid'),
      dataIndex: 'amountPaid',
      key: 'amountPaid',
      sorter: {
        compare: (
          a: OrderTableDataTypes,
          b: OrderTableDataTypes
        ) => a.amountPaid - b.amountPaid
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: OrderTableDataTypes) => (
        <Typography.Text>
          {
            data.amountPaid.toLocaleString()
          }
        </Typography.Text>
      ),
    },
    {
      title: t('order.remainAmounting'),
      dataIndex: 'remainAmounting',
      key: 'remainAmounting',
      sorter: {
        compare: (
          a: OrderTableDataTypes,
          b: OrderTableDataTypes
        ) => a.remainAmounting - b.remainAmounting
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: OrderTableDataTypes) => (
        <Typography.Text style={{ color: data.remainAmounting > 0 ? 'red' : 'unset' }}>
          {
            data.remainAmounting.toLocaleString()
          }
        </Typography.Text>
      ),
    },
    {
      title: t('order.serviceFee'),
      dataIndex: 'serviceFee',
      key: 'serviceFee',
      sorter: {
        compare: (
          a: OrderTableDataTypes,
          b: OrderTableDataTypes
        ) => (a?.serviceFee || 0) - (b?.serviceFee || 0)
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: OrderTableDataTypes) => (
        <Typography.Text>
          {
            data?.serviceFee?.toLocaleString()
          }
        </Typography.Text>
      ),
    },
    {
      title: t('order.serviceFeePrice'),
      dataIndex: 'serviceFeePrice',
      key: 'serviceFeePrice',
      sorter: {
        compare: (
          a: OrderTableDataTypes,
          b: OrderTableDataTypes
        ) => (a?.serviceFeePrice || 0) - (b?.serviceFeePrice || 0)
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: OrderTableDataTypes) => (
        <Typography.Text>
          {
            data.serviceFeePrice
          }
        </Typography.Text>
      ),
    },
    {
      title: t('system.status'),
      dataIndex: 'status',
      key: 'status',
      sorter: {
        compare: (
          a: OrderTableDataTypes,
          b: OrderTableDataTypes
        ) => a.status - b.status
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: OrderTableDataTypes) => (
        <Typography.Text>
          {
            statusData.find((item) => (item.value === data.status))?.label
          }
        </Typography.Text>
      ),
    },
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: {
        compare: (a: OrderTableDataTypes, b: OrderTableDataTypes) => {
          const aDate = new Date(a.updatedAt);
          const bDate = new Date(b.updatedAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: OrderTableDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.updatedAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, data: OrderTableDataTypes) => (
        <Space>
          <Button
            disabled={!roleUpdate}
            icon={<EditOutlined />}
            onClick={() => navigate(`${ROUTE_PATHS.ORDER_DETAIL}?id=${data.id}`)}
          />
          <Button
            disabled={!roleDelete}
            icon={<DeleteOutlined />}
            onClick={() => {
              Modal.confirm({
                className: 't-pagetable_deleteRecordModal',
                autoFocusButton: 'cancel',
                title: t('message.confirmDeleteRecord'),
                okText: t('system.ok'),
                cancelText: t('system.cancel'),
                cancelButtonProps: {
                  type: 'primary',
                },
                okButtonProps: {
                  type: 'default',
                },
                onOk: () => {
                  deleteOrdersMutate([data.id]);
                },
              });
            }}
          />
        </Space>
      ),
    },
  ];

  const orderData = useMemo(() => (orders?.data?.map((val) => ({
    id: val.orderData.id,
    code: val.orderData.code,
    total: val.orderData.total,
    tax: val.orderData.tax,
    grandTotal: val.orderData.grandTotal,
    updatedAt: val.orderData.updatedAt,
    status: val.orderData.status,
    paidStatus: val.orderData.paidStatus,
    discount: val.orderData.discount,
    nameShop: val.shop?.name || '',
    amountPaid: val.orderData.paid,
    remainAmounting: val.orderData.remainingPaid,
    serviceFee: val.orderData.serviceFee,
    serviceFeePrice: val.orderData.serviceFeePrice,
  })) || []), [orders]);

  /* Functions */
  const onDelete = async (data: OrderTableDataTypes[], code?: string) => {
    switch (code) {
      case 'allRow':
      case 'all': {
        deleteOrdersMutate(data.map((item) => item.id));
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('order', advancedFilter),
    [advancedFilter]
  );

  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
  };

  useEffect(() => {
    setSearchParams(mappingFilterToQuery(selectedFilterList));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterList]);

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.order')}
        rightHeader={(
          <Button
            disabled={!roleOther.includes(roles.ORDER_EXPORT) || exportOrdersLoading}
            icon={<ExportOutlined />}
            onClick={() => exportOrdersMutate()}
          >
            {t('system.export')}
          </Button>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          handleDelete={onDelete}
          handleSearch={setKeyword}
          isLoading={isLoading || deleteStaticBlockLoading}
          roles={{
            roleCreate: false,
            roleDelete,
            roleUpdate
          }}
          tableProps={{
            initShowColumns: ['id', 'shop', 'code', 'total', 'tax', 'grandTotal', 'discount',
              'updatedAt', 'status', 'paidStatus', 'amountPaid', 'remainAmounting', 'serviceFee', 'serviceFeePrice', 'action'],
            columns,
            pageData: orderData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: orders?.meta.total,
            noDeleteLanguage: true,
            noBaseCol: true,
            filterFields
          }}
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
            handleDeleteFilter,
          }}
        />
      </div>
    </>
  );
};

export default OrderManagement;
