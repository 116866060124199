import { SaveOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Card,
  Col, message, Row, Space, Spin, Typography
} from 'antd';
import moment from 'moment';
import React, {
  useEffect, useState
} from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { DropdownElement } from 'common/components/DropdownType';
import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import ManagementInfo from 'common/components/ManagementInfo';
import ModalConfirm from 'common/components/ModalConfirm';
import SelectFile from 'common/components/SelectFile';
import StatusLabel, { StatusButtons } from 'common/components/StatusLabel';
import {
  changeStatusShopService, createShopService, getShopByIdService, updateShopByIdService
} from 'common/services/extends/shops';
import { CreateUpdateShopParamsTypes } from 'common/services/extends/shops/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { generateSlug } from 'common/utils/functions';
import { useValidations } from 'common/utils/schemas';
import roles from 'configs/roles';

type MainDataTypes = {
  name: string;
  slug: string,
  url: string,
  image: string,
  imageTitle?: string;
  imageAlt?: string;
  color: string | null;
  categoryIds: Array<number> | null;
  brandIds: Array<number> | null;
  discountValue: string,
  percentDiscount: number,
  serviceFee: number
};

const ShopDetail: React.FC<ActiveRoles> = ({ roleCreate, roleUpdate, roleOther }) => {
  const { shopDetail } = useValidations();

  const mainMethod = useForm<MainDataTypes>({
    resolver: yupResolver(shopDetail),
    mode: 'onChange',
    defaultValues: {
      name: '',
      slug: '',
      url: '',
      image: '',
      color: '',
      categoryIds: [],
      brandIds: [],
      discountValue: '',
      percentDiscount: 0,
      serviceFee: 0
    }
  });

  /* Hooks */
  const { t } = useTranslation();
  const navigator = useNavigate();
  const [searchParams] = useSearchParams();
  const idParam = Number(searchParams.get('id'));
  const [status, setStatus] = useState<number>(1);
  /* States */
  const [confirm, setConfirm] = useState<string | undefined>(undefined);
  const queryKeyDetail = ['getDetailShop', idParam];
  const queryClient = useQueryClient();
  const { data: detailShop, isLoading: loadingDetail } = useQuery(
    [queryKeyDetail],
    () => {
      if (idParam) {
        return getShopByIdService({ id: String(idParam) });
      }
      return undefined;
    }
  );

  const { mutate: updateShopByIdMutate, isLoading: updateLoading } = useMutation(
    'updateShopById',
    async (data: {
      id: number;
      params: CreateUpdateShopParamsTypes
    }) => updateShopByIdService(data.id, data.params),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
      },
      onError: () => {
        message.error(t('message.updateError'));
      }
    }
  );

  const { mutate: createShopMutate, isLoading: createLoading } = useMutation(
    'createShop',
    createShopService,
    {
      onSuccess: () => {
        message.success(t('message.createSuccess'));
        navigator(`${ROUTE_PATHS.SHOPS_MANAGEMENT}`);
      },
      onError: () => {
        message.error(t('message.createError'));
      }
    }
  );

  const handleSubmit = async () => {
    const result = await mainMethod.trigger();
    if (!result) {
      return;
    }

    const mainData = mainMethod.getValues();

    const dataSubmit: CreateUpdateShopParamsTypes = {
      name: mainData.name,
      url: mainData.url,
      slug: mainData.slug,
      color: mainData.color,
      discount: mainData.percentDiscount || 0,
      image: {
        alt: mainData.imageAlt || '',
        path: mainData.image,
        title: mainData.imageTitle || '',
      },
      categoryIds: mainData.categoryIds!,
      brandIds: mainData.brandIds!,
      discountValue: mainData.discountValue,
      serviceFee: Number(mainData.serviceFee)
    };
    try {
      if (idParam) {
        updateShopByIdMutate({
          id: idParam,
          params: dataSubmit
        });
      } else {
        createShopMutate(dataSubmit);
      }
    } catch (error: any) {
      message.error(idParam ? t('system.updateError') : t('shops.createError'));
    }
  };

  /* Effects */
  useEffect(() => {
    if (detailShop) {
      setStatus(detailShop.shopData.status);
      mainMethod.reset({
        name: detailShop.shopData.name,
        url: detailShop.shopData.url,
        slug: detailShop.shopData.slug,
        image: detailShop.shopData.image.path,
        imageAlt: detailShop.shopData.image.alt,
        imageTitle: detailShop.shopData.image.title,
        categoryIds: detailShop.categories.map((x) => x.id),
        brandIds: detailShop.brands.map((x) => x.id),
        color: detailShop.shopData.color,
        discountValue: detailShop.shopData.discountValue,
        percentDiscount: detailShop.shopData.discount,
        serviceFee: detailShop.shopData.serviceFee
      });
    } else {
      mainMethod.reset();
    }
  }, [detailShop, mainMethod]);

  const { mutate: updateStatusMutate, isLoading: updateStatusLoading } = useMutation(
    'changeStatusShopService',
    async (data: {
      id: number, statusId: number
    }) => changeStatusShopService(data.id, data.statusId),
    {
      onSuccess: (_, params) => {
        setStatus(params.statusId);
        message.success(t('message.updateStatusSuccess'));
        queryClient.invalidateQueries(queryKeyDetail);
      },
      onError: () => {
        message.error(t('message.updateStatusError'));
      }
    }
  );
  const { isDirty } = mainMethod.formState;

  const handleChangeStatusAndSave = async (stat: number) => {
    if (!idParam) {
      await handleSubmit();
    } else {
      if (isDirty) {
        await handleSubmit();
      }
      updateStatusMutate({ id: idParam, statusId: stat });
    }
  };
  /* Render */
  return (
    <div className="p-editStaticBlock">
      <HeaderPage
        fixed
        title={idParam ? t('system.edit') : t('system.create')}
        rightHeader={(
          <Space size={16}>
            <StatusButtons
              loading={updateStatusLoading}
              status={status}
              isApprove={roleOther.includes(roles.SHOPS_APPROVED)}
              handleChangeStatus={(stat) => {
                handleChangeStatusAndSave(stat);
              }}
            />
            <Button
              type="primary"
              disabled={(idParam && !roleUpdate) || (!idParam && !roleCreate)}
              onClick={() => handleSubmit()}
              loading={updateLoading || createLoading}
            >
              <SaveOutlined />
              {t('system.save')}
            </Button>
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <Row gutter={16}>
          <Col xxl={18} xl={16} lg={16}>
            {loadingDetail ? <Spin className="center-absolute" size="large" spinning />
              : (
                <Card
                  type="inner"
                >
                  <div className="site-card-border-less-wrapper">
                    <FormProvider {...mainMethod}>
                      <Space direction="vertical" size={12} style={{ width: '100%' }}>
                        <Row gutter={16}>
                          <Col span={12}>
                            <div className="p-editPageTemplate_input">
                              <Typography.Text strong>
                                {t('system.name')}
                                {' '}
                              </Typography.Text>
                              <Typography.Text strong type="danger">
                                *
                              </Typography.Text>
                              <Controller
                                name="name"
                                control={mainMethod.control}
                                render={({
                                  field: { value, onChange },
                                  fieldState: { error },
                                }) => (
                                  <Input
                                    className="u-mt-8"
                                    name="title"
                                    value={value}
                                    onChange={(e) => {
                                      onChange(e);
                                      if (!idParam) {
                                        mainMethod.setValue('slug', generateSlug(e.currentTarget.value));
                                      }
                                    }}
                                    error={error?.message}
                                    size="large"
                                  />
                                )}
                              />
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="p-editPageTemplate_input">
                              <Typography.Text strong>
                                {t('system.slug')}
                                {' '}
                              </Typography.Text>
                              <Typography.Text strong type="danger">
                                *
                              </Typography.Text>
                              <Controller
                                name="slug"
                                control={mainMethod.control}
                                render={({
                                  field: { value, onChange },
                                  fieldState: { error },
                                }) => (
                                  <Input
                                    className="u-mt-8"
                                    name="slug"
                                    value={value}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                    error={error?.message}
                                    size="large"
                                  />
                                )}
                              />
                            </div>
                          </Col>
                          <Col span={24}>
                            <div className="p-editPageTemplate_input u-mt-12">
                              <Typography.Text strong>
                                {t('shop.color')}
                              </Typography.Text>
                              <Controller
                                name="color"
                                control={mainMethod.control}
                                render={({
                                  field: { value, onChange },
                                  fieldState: { error },
                                }) => (
                                  <Input
                                    className="u-mt-8"
                                    value={value || ''}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                    error={error?.message}
                                    size="large"
                                  />
                                )}
                              />
                            </div>
                          </Col>
                          <Col span={24}>
                            <div className="p-editPageTemplate_input u-mt-12">
                              <Typography.Text strong>
                                {t('formManagement.urlTypeLabel')}
                                {' '}
                              </Typography.Text>
                              <Typography.Text strong type="danger">
                                *
                              </Typography.Text>
                              <Controller
                                name="url"
                                control={mainMethod.control}
                                render={({
                                  field: { value, onChange },
                                  fieldState: { error },
                                }) => (
                                  <Input
                                    className="u-mt-8"
                                    name="url"
                                    value={value}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                    error={error?.message}
                                    size="large"
                                  />
                                )}
                              />
                            </div>
                          </Col>

                          <Col span={24}>
                            <div className="p-editPageTemplate_input u-mt-12">
                              <Typography.Text strong>
                                {t('shop.discount')}
                                {' '}
                              </Typography.Text>
                              <Controller
                                name="discountValue"
                                control={mainMethod.control}
                                render={({
                                  field: { value, onChange },
                                  fieldState: { error },
                                }) => (
                                  <Input
                                    className="u-mt-8"
                                    name="discountValue"
                                    value={value}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                    error={error?.message}
                                    size="large"
                                  />
                                )}
                              />
                            </div>
                          </Col>

                          <Col span={24} className="u-mt-16">
                            <Typography.Text strong>
                              {t('shop.titleShopCategory')}
                              {' '}
                            </Typography.Text>
                            <Controller
                              name="categoryIds"
                              control={mainMethod.control}
                              render={({ field, fieldState }) => (
                                <>
                                  <DropdownElement
                                    type="category"
                                    placeholder={`${t('system.select')} ${t('shop.titleShopCategory').toLocaleLowerCase()}`}
                                    locale="vi"
                                    value={field.value}
                                    onChange={field.onChange}
                                    multiple={{}}
                                  />
                                  {fieldState.error && (
                                    <span
                                      className="a-input_errorMessage"
                                    >
                                      {fieldState.error.message}
                                    </span>
                                  )}
                                </>
                              )}
                            />
                          </Col>

                          <Col span={24} className="u-mt-16">
                            <Typography.Text strong>
                              {t('shop.titleShopBrand')}
                              {' '}
                            </Typography.Text>
                            <Controller
                              name="brandIds"
                              control={mainMethod.control}
                              render={({ field, fieldState }) => (
                                <>
                                  <DropdownElement
                                    type="brand"
                                    placeholder={`${t('system.select')} ${t('shop.titleShopBrand').toLocaleLowerCase()}`}
                                    locale="vi"
                                    value={field.value}
                                    onChange={field.onChange}
                                    multiple={{}}
                                  />
                                  {fieldState.error && (
                                    <span
                                      className="a-input_errorMessage"
                                    >
                                      {fieldState.error.message}
                                    </span>
                                  )}
                                </>
                              )}
                            />
                          </Col>

                          <Col span={24}>
                            <div className="p-editPageTemplate_input u-mt-12">
                              <Typography.Text strong>
                                {t('shop.percentDiscount')}
                                {' '}
                              </Typography.Text>
                              <Controller
                                name="percentDiscount"
                                control={mainMethod.control}
                                render={({
                                  field: { value, onChange },
                                  fieldState: { error },
                                }) => (
                                  <Input
                                    className="u-mt-8"
                                    name="percentDiscount"
                                    type="number"
                                    value={value}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                    error={error?.message}
                                    size="large"
                                  />
                                )}
                              />
                            </div>
                          </Col>
                          <Col span={24}>
                            <div className="p-editPageTemplate_input u-mt-12">
                              <Typography.Text strong>
                                {t('shop.serviceFeePercent')}
                                {' '}
                              </Typography.Text>
                              <Controller
                                name="serviceFee"
                                control={mainMethod.control}
                                render={({
                                  field: { value, onChange },
                                  fieldState: { error },
                                }) => (
                                  <Input
                                    className="u-mt-8"
                                    type="number"
                                    value={value}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                    error={error?.message}
                                    size="large"
                                  />
                                )}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col span={24}>
                            <Controller
                              control={mainMethod.control}
                              name="image"
                              render={({
                                field: { value, onChange },
                                fieldState
                              }) => (
                                <>
                                  <SelectFile
                                    value={value}
                                    name="image"
                                    titleName="imageTitle"
                                    altName="imageAlt"
                                    hasOptions
                                    handleSelect={(url, title, alt) => {
                                      onChange(url);
                                      mainMethod.setValue('imageTitle', title);
                                      mainMethod.setValue('imageAlt', alt);
                                    }}
                                    handleDelete={() => onChange(undefined)}
                                    title={(
                                      <>
                                        <Typography.Text strong>
                                          {t('system.image')}
                                        </Typography.Text>
                                        {' '}
                                        <Typography.Text strong type="danger">
                                          *
                                        </Typography.Text>
                                      </>
                                    )}
                                  />
                                  {fieldState.error && (
                                    <span
                                      className="a-input_errorMessage"
                                    >
                                      {fieldState.error.message}
                                    </span>
                                  )}
                                </>
                              )}
                            />
                          </Col>
                        </Row>
                      </Space>
                    </FormProvider>
                  </div>
                </Card>
              )}
          </Col>
          <Col xxl={6} xl={8} lg={8}>
            <div className="u-mb-16">
              {status && <StatusLabel status={status} bigger />}
            </div>
            <ManagementInfo
              createdDate={detailShop ? moment(detailShop.shopData.createdAt).format('HH:mm DD/MM/yyyy') : ''}
              createdBy={detailShop?.creator.name || ''}
              lastUpdated={detailShop ? moment(detailShop?.shopData.updatedAt).format('HH:mm DD/MM/yyyy') : ''}
              lastUpdatedBy={detailShop?.updater.name || ''}
            />
          </Col>
        </Row>
      </div>
      <ModalConfirm
        isShow={!!confirm}
        handleCancel={() => { }}
        handleConfirm={() => { }}
        handleClose={() => setConfirm(undefined)}
      >
        {t('message.confirmSave')}
      </ModalConfirm>
    </div>
  );
};

export default ShopDetail;
