import {
  CreateUpdateOrderParamsTypes,
  DeleteShopParamsTypes,
  GetAllOrderDataTypes,
} from './types';

import axiosInstance from 'common/services/common/instance';

export const getOrdersService = async (params?: BaseFilterParams):
  Promise<APIPaginationResponse<GetAllOrderDataTypes[]>> => {
  const res = await axiosInstance.get('orders', { params });
  return res.data;
};

export const deleteOrderService = async (
  params: DeleteShopParamsTypes
) => axiosInstance.delete('orders', { data: params });

export const getOrderByIdService = async (
  params: { id: string }
): Promise<GetAllOrderDataTypes> => {
  const res = await axiosInstance.get(`orders/${params.id}`);
  return res.data.data;
};

export const getOrdersExportsService = async (params?: BaseFilterParams):
  Promise<{ link: string }> => {
  const res = await axiosInstance.get('orders/exports', { params });
  return res.data.data;
};

export const updateOrderByIdService = async (id: number, params: CreateUpdateOrderParamsTypes) => axiosInstance.put(`orders/${id}`, params);
