import { SaveOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Card,
  Col, message, Row, Space, Spin, Typography
} from 'antd';
import moment from 'moment';
import React, {
  useEffect, useState
} from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import ManagementInfo from 'common/components/ManagementInfo';
import ModalConfirm from 'common/components/ModalConfirm';
import StatusLabel, { StatusButtons } from 'common/components/StatusLabel';
import {
  changeStatusBrandService,
  createBrandsService,
  getBrandsByIdService, updateBrandsByIdService
} from 'common/services/extends/brand';
import { CreateUpdateBrandsParamsTypes } from 'common/services/extends/brand/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { useValidations } from 'common/utils/schemas';
import roles from 'configs/roles';

type MainDataTypes = {
  id: number;
  name: string;
  displayOrder: number;
};

const BrandDetail: React.FC<ActiveRoles> = ({ roleCreate, roleUpdate, roleOther }) => {
  const { brandDetail } = useValidations();
  const [status, setStatus] = useState<number>(1);
  const queryClient = useQueryClient();

  const mainMethod = useForm<MainDataTypes>({
    resolver: yupResolver(brandDetail),
    mode: 'onChange',
  });

  /* Hooks */
  const { t } = useTranslation();
  const navigator = useNavigate();
  const [searchParams] = useSearchParams();
  const idParam = Number(searchParams.get('id'));

  /* States */
  const [confirm, setConfirm] = useState<string | undefined>(undefined);

  const queryKeyDetail = ['getDetailBrand', idParam];
  const { data: detailBrand, isLoading: loadingDetail } = useQuery(
    [queryKeyDetail],
    () => {
      if (idParam) {
        return getBrandsByIdService({ id: String(idParam) });
      }
      return undefined;
    }
  );

  const { mutate: updateBrandsByIdMutate, isLoading: updateLoading } = useMutation(
    'updateBrandById',
    async (data: {
      id: number;
      params: CreateUpdateBrandsParamsTypes
    }) => updateBrandsByIdService(data.id, data.params),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
        navigator(`${ROUTE_PATHS.BRAND_MANAGEMENT}`);
      },
      onError: () => {
        message.error(t('message.updateError'));
      }
    }
  );

  const { mutate: createBrandMutate, isLoading: createLoading } = useMutation(
    'createBrand',
    createBrandsService,
    {
      onSuccess: () => {
        message.success(t('message.createSuccess'));
        navigator(`${ROUTE_PATHS.BRAND_MANAGEMENT}`);
      },
      onError: () => {
        message.error(t('message.createError'));
      }
    }
  );

  const handleSubmit = async () => {
    const result = await mainMethod.trigger();
    if (!result) {
      return;
    }

    const mainData = mainMethod.getValues();

    const dataSubmit: CreateUpdateBrandsParamsTypes = {
      name: mainData.name,
      displayOrder: mainData.displayOrder,
    };

    try {
      if (idParam) {
        updateBrandsByIdMutate({
          id: idParam,
          params: dataSubmit
        });
      } else {
        createBrandMutate(dataSubmit);
      }
    } catch (error: any) {
      message.error(idParam ? t('system.updateError') : t('shops.createError'));
    }
  };

  const { mutate: updateStatusMutate, isLoading: updateStatusLoading } = useMutation(
    'changeStatusBrandService',
    async (data: {
      id: number, statusId: number
    }) => changeStatusBrandService(data.id, data.statusId),
    {
      onSuccess: (_, params) => {
        setStatus(params.statusId);
        message.success(t('message.updateStatusSuccess'));
        queryClient.invalidateQueries(queryKeyDetail);
      },
      onError: () => {
        message.error(t('message.updateStatusError'));
      }
    }
  );

  const { isDirty } = mainMethod.formState;

  const handleChangeStatusAndSave = async (stat: number) => {
    if (!idParam) {
      await handleSubmit();
    } else {
      if (isDirty) {
        await handleSubmit();
      }
      updateStatusMutate({ id: idParam, statusId: stat });
    }
  };

  /* Effects */
  useEffect(() => {
    if (detailBrand) {
      mainMethod.reset({
        name: detailBrand.brandData.name,
        displayOrder: detailBrand.brandData.displayOrder,
      });
      setStatus(detailBrand.brandData.status);
    } else {
      mainMethod.reset();
    }
  }, [detailBrand, mainMethod]);

  /* Render */
  return (
    <div className="p-editStaticBlock">
      <HeaderPage
        fixed
        title={idParam ? t('system.edit') : t('system.create')}
        rightHeader={(
          <Space size={16}>
            <StatusButtons
              loading={updateStatusLoading}
              status={status}
              isApprove={roleOther.includes(roles.CATEGORIES_APPROVED)}
              handleChangeStatus={(stat) => {
                handleChangeStatusAndSave(stat);
              }}
            />
            <Button
              type="primary"
              disabled={(idParam && !roleUpdate) || (!idParam && !roleCreate)}
              onClick={() => handleSubmit()}
              loading={updateLoading || createLoading}
            >
              <SaveOutlined />
              {t('system.save')}
            </Button>
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <Row gutter={16}>
          <Col xxl={18} xl={16} lg={16}>
            {loadingDetail ? <Spin className="center-absolute" size="large" spinning />
              : (
                <Card
                  type="inner"
                >
                  <div className="site-card-border-less-wrapper">
                    <FormProvider {...mainMethod}>
                      <Space direction="vertical" size={12} style={{ width: '100%' }}>
                        <Row gutter={16}>
                          <Col span={12}>
                            <div className="p-editPageTemplate_input">
                              <Typography.Text strong>
                                {t('system.name')}
                                {' '}
                              </Typography.Text>
                              <Typography.Text strong type="danger">
                                *
                              </Typography.Text>
                              <Controller
                                name="name"
                                control={mainMethod.control}
                                render={({
                                  field: { value, onChange },
                                  fieldState: { error },
                                }) => (
                                  <Input
                                    className="u-mt-8"
                                    name="title"
                                    value={value}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                    error={error?.message}
                                    size="large"
                                  />
                                )}
                              />
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="p-editPageTemplate_input">
                              <Typography.Text strong>
                                {t('newsDetail.displayOrder')}
                                {' '}
                              </Typography.Text>
                              <Typography.Text strong type="danger">
                                *
                              </Typography.Text>
                              <Controller
                                name="displayOrder"
                                control={mainMethod.control}
                                render={({
                                  field: { value, onChange },
                                  fieldState: { error },
                                }) => (
                                  <Input
                                    className="u-mt-8"
                                    name="displayOrder"
                                    type="number"
                                    value={value}
                                    onChange={onChange}
                                    error={error?.message}
                                    size="large"
                                  />
                                )}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Space>
                    </FormProvider>
                  </div>
                </Card>
              )}
          </Col>
          <Col xxl={6} xl={8} lg={8}>
            <div className="u-mb-16">
              {status && <StatusLabel status={status} bigger />}
            </div>
            <ManagementInfo
              createdDate={detailBrand ? moment(detailBrand.brandData.createdAt).format('HH:mm DD/MM/yyyy') : ''}
              createdBy=""
              lastUpdated={detailBrand ? moment(detailBrand?.brandData.updatedAt).format('HH:mm DD/MM/yyyy') : ''}
              lastUpdatedBy=""
            />
          </Col>
        </Row>
      </div>
      <ModalConfirm
        isShow={!!confirm}
        handleCancel={() => { }}
        handleConfirm={() => { }}
        handleClose={() => setConfirm(undefined)}
      >
        {t('message.confirmSave')}
      </ModalConfirm>
    </div>
  );
};

export default BrandDetail;
