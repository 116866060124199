import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Modal, Space, Typography,
  message
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import StatusLabel from 'common/components/StatusLabel';
import getBrandsService, { deleteBrandsService } from 'common/services/extends/brand';
import { ROUTE_PATHS } from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';

type BrandDataTypes = {
  id: number;
  name: string,
  displayOrder: string,
  updatedAt: string;
  status: number;
};

const BrandManagement: React.FC<ActiveRoles> = ({
  roleCreate, roleDelete, roleView, roleUpdate
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  /* Selectors */
  const {
    defaultPageSize
  } = useAppSelector((state) => state.system);

  /* States */
  const [currentPage, setCurrentPage] = useState(1);
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');

  /* Queries */
  const { data: brands, isLoading } = useQuery(
    ['getAllBrand', keyword, currentPage, currentView],
    () => getBrandsService({
      keyword, page: currentPage, limit: currentView
    }),
  );

  const { mutate: deleteBrandMutate, isLoading: deleteStaticBlockLoading } = useMutation(
    ['brandDelete'],
    async (ids: number[]) => deleteBrandsService({ ids }),
    {
      onSuccess: () => {
        message.success(t('message.deleteSuccess'));
        queryClient.invalidateQueries(['getAllBrand']);
      },
      onError: () => {
        message.error(t('message.deleteError'));
      }
    }
  );

  /* Variables */
  const columns: ColumnsType<BrandDataTypes> = [
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      sorter: {
        compare: (a: BrandDataTypes, b: BrandDataTypes) => a.id - b.id,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: BrandDataTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    // --- Tên
    {
      title: t('system.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (
          a: BrandDataTypes,
          b: BrandDataTypes
        ) => a.name.localeCompare(b.name)
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: BrandDataTypes) => (
        <Typography.Text
          onClick={() => roleView && navigate(`${ROUTE_PATHS.BRAND_DETAIL}?id=${data.id}`)}
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.name}
        </Typography.Text>
      ),
    },
    // --- displayOrder
    {
      title: t('newsDetail.displayOrder'),
      dataIndex: 'displayOrder',
      key: 'displayOrder',
      sorter: {
        compare: (
          a: BrandDataTypes,
          b: BrandDataTypes
        ) => a.displayOrder.localeCompare(b.displayOrder)
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: BrandDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {data.displayOrder}
        </Typography.Text>
      ),
    },
    {
      title: t('system.status'),
      dataIndex: 'status',
      key: 'status',
      width: 160,
      render: (_name: string, data: BrandDataTypes) => (
        <StatusLabel status={data.status} />
      ),
    },
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: {
        compare: (a: BrandDataTypes, b: BrandDataTypes) => {
          const aDate = new Date(a.updatedAt);
          const bDate = new Date(b.updatedAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: BrandDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.updatedAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, data: BrandDataTypes) => (
        <Space>
          <Button
            disabled={!roleUpdate}
            icon={<EditOutlined />}
            onClick={() => navigate(`${ROUTE_PATHS.BRAND_DETAIL}?id=${data.id}`)}
          />
          <Button
            disabled={!roleDelete}
            icon={<DeleteOutlined />}
            onClick={() => {
              Modal.confirm({
                className: 't-pagetable_deleteRecordModal',
                autoFocusButton: 'cancel',
                title: t('message.confirmDeleteRecord'),
                okText: t('system.ok'),
                cancelText: t('system.cancel'),
                cancelButtonProps: {
                  type: 'primary',
                },
                okButtonProps: {
                  type: 'default',
                },
                onOk: () => {
                  deleteBrandMutate([data.id]);
                },
              });
            }}
          />
        </Space>
      ),
    },
  ];

  const brandData = useMemo(() => (brands?.data?.map((val) => ({
    id: val.brandData.id,
    name: val.brandData.name,
    displayOrder: String(val.brandData.displayOrder),
    updatedAt: val.brandData.updatedAt,
    status: val.brandData.status
  })) || []), [brands]);

  /* Functions */
  const onDelete = async (data: BrandDataTypes[], code?: string) => {
    switch (code) {
      case 'allRow':
      case 'all': {
        deleteBrandMutate(data.map((item) => item.id));
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.brand')}
        rightHeader={(
          <Button type="primary" disabled={!roleCreate} onClick={() => navigate(`${ROUTE_PATHS.BRAND_DETAIL}`)}>
            <PlusOutlined />
            {t('system.create')}
          </Button>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          handleDelete={onDelete}
          handleSearch={setKeyword}
          isLoading={isLoading || deleteStaticBlockLoading}
          roles={{
            roleCreate,
            roleDelete,
            roleUpdate
          }}
          tableProps={{
            initShowColumns: ['id', 'name', 'displayOrder', 'updatedAt', 'status', 'action'],
            columns,
            pageData: brandData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: brands?.meta.total,
            noDeleteLanguage: true,
            noBaseCol: true
          }}
        />
      </div>
    </>
  );
};

export default BrandManagement;
